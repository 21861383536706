import React, { useState, useEffect } from 'react';
import "@src/scss/App.scss";
import { graphql } from 'gatsby';
import gql from "graphql-tag";
import { useQuery } from '@apollo/client';
import Seo from "@components/seo";
import Layout from "@components/layout";
import ModuleSection from '@components/modules/moduleWrapper';
import BannerRender from "@components/modules/Banner/BannerRender";
import { Stack } from "react-bootstrap";
import TeamCard from "@components/modules/Team/teamCard";
import { Routes } from '@components/routes';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Loader from '@components/loader';
import Select from 'react-select';

import { motion } from "framer-motion";
import { contentItem } from '@components/utils/animations';

const GET_ALL_TEAMS = gql`
    query GetTeams($tagsInput: String, $sort: String, $limit: Int, $start: Int) {
        teams(publicationState: LIVE, sort: $sort, limit: $limit, start: $start, where: {Department_contains: $tagsInput}) {
            Name
            Slug
            id
            JobTitle
            Phone
            WhatsApp
            Email
            Order
            Department
            imagetransforms
            Image {
                url
            }
        }
    }
`;

const sliderConfig = {
    slidesPerView: "auto",
    spaceBetween: 32,
    pagination: false,
    breakpoints: {
        1200: {
            slidesPerView: "auto",
            spaceBetween: 40
        }
    }
}

const AfterTitle = ({ teams, setDepartment }) => {
    const tags_refetched = [...new Set(teams?.map(item => item.Department))];
    const tags = ["","Management","Agents","Support"];

    return(
        <>
            {teams?.length > 0 &&
                <div variants={contentItem} className="mt-32 mt-md-40 mt-lg-56">
                    <Carousel className="image-banner-btn__carousel" {...sliderConfig}>
                        {tags.map((blog, i) => {
                            let addedBtnClass = {
                                to: "#",
                                variant: tags_refetched.length === 1 ? blog === tags_refetched[0] ? "link-secondary-underlined" : "link-secondary" : "link-secondary",
                                label: `${blog}`,
                                //onClick: () => refetch({ tagsInput: `${blog}` })
                            }
                            if (0 === i) {
                                return(
                                    <SwiperSlide key={i}>
                                        <Button {...addedBtnClass} variant={tags_refetched.length > 1 ? "link-secondary-underlined" : "link-secondary"} onClick={() => setDepartment("")}>Everyone</Button>
                                    </SwiperSlide>
                                )
                            } else {
                                return(
                                    <SwiperSlide key={i}>
                                        <Button {...addedBtnClass} onClick={() => setDepartment(blog)}>{blog}</Button>
                                    </SwiperSlide>
                                )
                            }
                        })}
                    </Carousel>
                </div>
            }
        </>
    )
}

// const sortOptions = [
//     { value: "Order:ASC", label: "By Order" },
//     { value: "Name:ASC", label: "Sort A-Z" },
//     { value: "Name:DESC", label: "Sort Z-A" }
// ]

const sortOptions = [
    { value: "Order_ASC", label: "By Order" },
    { value: "Name_ASC", label: "Sort A-Z" },
    { value: "Name_DESC", label: "Sort Z-A" }
]

const orderSelect = {
    option: (provided) => ({
        ...provided,
        color: "#5D5F66"
    }),
    control: (provided) => ({
        ...provided,
        background: "transparent",
        borderRadius: 0,
        border: "none",
        // height: 16,
        boxShadow: "none",
        // paddingLeft: 24,
        // paddingRight: 16
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: "#5D5F66"
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        marginTop: "-5px"
    }),
    indicatorSeparator: () => ({
        display: "none"
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#5D5F66",
        whiteSpace: "nowrap"
    }),
    singleValue: (provided) => {
        return {
            ...provided,
            color: "#5D5F66"
        };
    },
    // valueContainer: (provided) => ({
    //     ...provided,
    //     paddingLeft: 0,
    //     paddingRight: 0
    // })
}

const TeamLandingPages = (props) => {

    let [teamList, setTeamList] = useState([]);
    // let [teamListPagination, setTeamListPagination] = useState([]);
    let [department, setDepartment] = useState("");
    let [sortOrder, setSortOrder] = useState("");

    // const [] = useState(0);
	const [postsToShow, setPostsToShow] = useState([]);
	const [next, setNext] = useState(12);

    const postsPerPage = 12;
	let arrayForHoldingPosts = [];

    // const { loading, error, data, refetch } = useQuery(GET_ALL_TEAMS, { variables: { tagsInput: "", sort: "Order:ASC" } });
    const { glstrapi: { page, teams } } = props?.data

    const data = props?.data?.glstrapi

    useEffect(() => {
        let team_list = teams;
        if(department){
            team_list = team_list.length > 0 && team_list.filter((item => item.Department === department))
        }
        if(sortOrder){
            if(sortOrder === "Name_ASC"){
                team_list.sort((a, b) => a.Name.localeCompare(b.Name));
            } 
            if(sortOrder === "Name_DESC"){
                team_list.sort((a, b) => b.Name.localeCompare(a.Name));
            }
            if(sortOrder === "Order_ASC"){
                team_list.sort((a, b) => a.Order - b.Order);
            } 
        }
        setTeamList(team_list)
        
    },[teams, department, sortOrder])
    

    const breadcrumbs = [
        { label: "About", url: `${Routes.AboutPage}` },
        { label: page?.Pagename, url: `${Routes.AboutPage}/${page?.Slug}` }
    ]

	
	const loopWithSlice = (start, end) => {
        const slicedPosts = teamList?.slice(0, end);
        if(teamList){
            arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
            setPostsToShow(arrayForHoldingPosts);
        }
	};

	const handleShowMorePosts = () => {
		loopWithSlice(next, next + postsPerPage);
		setNext(next + postsPerPage);
	};

	useEffect(() => {
        //console.log("teamList 2", teamList)
		loopWithSlice(0, postsPerPage);
        setNext(12)
	}, [teamList, sortOrder]);

    return (
        <Layout bodyClass="teams-landing">
            <Seo
                title={page?.Metadata?.title ? page.Metadata.title : page.Title}
                description={page?.Metadata?.description ? page.Metadata.description : null}
                image={page?.Metadata?.image?.url ? page.Metadata.image.url : null}
            />
            <BannerRender
                page={page}
                breadcrumbs={breadcrumbs}
                afterTitle={<AfterTitle teams={teamList} setDepartment={setDepartment} />}
            />
            <ModuleSection sectionSpacing="py-40 py-md-64 py-lg-80">
                <Loader>
                    <Stack gap={32} direction="horizontal" className="teams-filters mb-24 mb-md-40">
                        <div>Showing {teamList?.length} Staff Members</div>
                        <div className="ms-auto">
                            <Select
                                placeholder="By Order"
                                options={sortOptions}
                                defaultValue="Order_ASC"
                                onChange={(e) => setSortOrder(e.value)}
                                styles={orderSelect}
                                isSearchable={false}
                            />
                        </div>
                    </Stack>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xxl-3 gx-0 gy-24 gx-md-16 gy-md-40 gx-lg-40">
                        {postsToShow?.map((team, i) => {
                            return(
                                <TeamCard
                                    key={team.id}
                                    team={team}
                                    custom={i}
                                />
                            )
                        })}
                        {postsToShow?.length !== teamList?.length &&
                            <Col xs={12} md={12} xxl={12}>
                                <Button
                                    variant="loadMore"
                                    // disabled={pageDetails && start + limit >= pageDetails?.totalCount}
                                    // onClick={() => refetch({ limit: setNext(next + postsPerPage) })}
                                    onClick={handleShowMorePosts}
                                    className="w-100"
                                >
                                    Load more
                                </Button>
                            </Col>
                        }
                    </div>
                </Loader>
            </ModuleSection>
        </Layout>
    );
}

export default TeamLandingPages

export const query = graphql`
query teamLandingQuery($id: ID!) {
    glstrapi {
        page(id: $id, publicationState: LIVE) {
            Pagename
            Title
            Slug
            HeaderTemplate
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    ...ImageBannerFragment
                }
            }
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }

        teams(publicationState: LIVE, sort:"Order:ASC") {
            Name
            Slug
            id
            JobTitle
            Phone
            WhatsApp
            Email
            Order
            Department
            imagetransforms
            Image {
                url
            }
        }
    }
}
`