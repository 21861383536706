import React from "react";
import Stack from "react-bootstrap/Stack";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonLink from '@components/ButtonLink';
import { Routes } from '@components/routes';
import { cleanP, onlyNumbers } from '@components/utils/CleanedHtml';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import { StaticImage } from "gatsby-plugin-image";
import { ModalConsumer } from '@components/modal/ModalContext';
import { ContactMemberModal } from '@components/modal/modals';
import { StarberryIcons } from "@components/icons/index";
import Lightbox from '@components/lightbox';
import { delayItemsFromBottom } from '@components/utils/animations';
import { uaenumberformat } from '@components/utils/CleanedHtml';
import './teamCard.scss'; 

const TeamCard = ({ team, className, custom, email_me, meet_me, view_profile, CountryFlagStatus, currentLanguage }) => {
    // GGFX
    let processedImages = JSON.stringify({});
    if (team?.imagetransforms?.Image_Transforms) {
        processedImages = team?.imagetransforms?.Image_Transforms;
    }

    let processedImagesVideoImg = JSON.stringify({});
    if (team?.imagetransforms?.VideoImg_Transforms) {
        processedImagesVideoImg = team?.imagetransforms?.VideoImg_Transforms;
    }

    const imagePath = process.env.GATSBY_SITE_URL + "/images/"

    //console.log('currentLanguage', currentLanguage)

    return (
        <Col
            className={className}
            variants={delayItemsFromBottom}
            custom={custom}
        >
            <Stack
                direction="horizontal"
                className={`team-card gap-16 gap-lg-40 ${CountryFlagStatus ? "team-card-country-page" : ""} `}
            >
                <div className={`team-card__image__wrapper ${email_me && "country_landing_page_teamCard"} `}>
                    <ButtonLink variant="none" manualLink={`${Routes.AboutPage}${Routes.TeamsPage}/${team?.Slug}`} className="team-card__image">
                        {team?.Image?.url ?
                            <>
                                <ImageTransform
                                    imagesources={team?.Image?.url}
                                    renderer="srcSet"
                                    imagename="team.Image.cardImage"
                                    attr={{ alt: cleanP(team?.Name) + ' -  Exclusive Links' }}
                                    imagetransformresult={processedImages}
                                    id={team.id}
                                />
                            </>
                            :
                            <>
                                <StaticImage
                                    src="../../../images/default-user-image.png"
                                    width={200}
                                    height={235}
                                    quality={100}
                                    layout="constrained"
                                    alt={`${cleanP(team?.Name)} -  Exclusive Links`}
                                />
                            </>
                        }
                    </ButtonLink>
                </div>

                <Stack direction="vertical" className="gap-4 team-card__text align-items-start">
                    <Stack className={CountryFlagStatus ? "country_enabled gap-4 mb-12" : "gap-4 mb-12"}>
                        {team?.Name &&
                            <span className="team-card__name">{team.Name}</span>
                        }
                        {team?.JobTitle &&
                            <span className="team-card__job-title">{team.JobTitle}</span>
                        }
                    </Stack>
                    <Stack className={` ${CountryFlagStatus ? `gap-4 gap-md-1 mb-3 mb-md-3` : `gap-4 mb-6 mb-md-12`} align-items-start team-card__btns`}>
                        {team?.WhatsApp &&
                            <ButtonLink manualLink={`https://wa.me/${onlyNumbers(team.WhatsApp)}`} label={uaenumberformat(team.WhatsApp)} icon="whatsAppIcon" variant="link-gray" className="whatsApp" />
                        }

                        {team?.Phone &&
                            <ButtonLink manualLink={`tel:${uaenumberformat(team.Phone)}`} label={uaenumberformat(team.Phone)} icon="phoneIcon" variant="link-gray" />
                        }
                        {team.Email &&
                            <ModalConsumer>
                                {({ showModal }) => (
                                    // <ButtonLink manualLink={`#`} onClick={() => showModal(ContactMemberModal, { show: true, title: `Email ${team?.Name?.split(' ')[0]}` })}  icon="emailIcon" variant="link-gray" btnClass="" />
                                    <Button variant="link-gray" className="email_btn" onClick={() => showModal(ContactMemberModal, { show: true, title: `Email ${team?.Name?.split(' ')[0]}`, email: team.Email })}><StarberryIcons iconName="emailIcon" className="bi" />{email_me ? <span>{email_me}</span> : "Email me"}</Button>
                                )}
                            </ModalConsumer>

                        }
                        {currentLanguage === "cn" &&
                            <ButtonLink manualLink={`${imagePath}wechat-QR-code.jpg`} label={`WeChat`} icon="weChatIcon" variant="link-gray" className="whatsApp weChatApp" />
                        }
                        {team.VideoUrl &&
                            <Lightbox videoUrl={[`${team?.VideoUrl}`]} lightboxType="youtube" hideVideoIcon={true}>
                                {team?.VideoImg?.url &&
                                    <div className="video-card__image__wrapper">
                                        <div className="video-wrapper d-flex justify-content-center align-items-start mt-2">
                                            <StarberryIcons iconName="videoIcon" className="bi video-icon mt-md-2" /> <span className="text-start" >{meet_me ? meet_me : "Meet me"}</span>
                                        </div>
                                    </div>
                                }
                            </Lightbox>

                        }
                    </Stack>
                    <div className="align-items-start team-card__more">
                        <ButtonLink variant="link-primary-underlined" manualLink={`${Routes.AboutPage}${Routes.TeamsPage}/${team?.Slug}`}>{view_profile ? view_profile : "View Profile"}</ButtonLink>
                    </div>
                </Stack>
            </Stack>
        </Col>
    )
}

export default TeamCard